import { CApi } from './api';
import common from '@/ts/common';

export class CLicenseApi extends CApi {
        save(data: object, path: string) {
                let param = JSON.parse(JSON.stringify(data));
                param.active_from = common.dateFormatSave(data.active_from);
                param.active_to = common.dateFormatSave(data.active_to);
                param.access_expiration_at = common.dateFormatSave(data.access_expiration_at);
                return this.post(this.getRoot('save'), { data: param, path });
        }

        async createAKeys(license_id : number, num:number) {
                let {data} = await this.post(this.getRoot("createAccessKey"), {license_id, num})
                return data.access_keys
        }

        async reserveAKeys(license_id : number, num:number) {
                let {data} = await this.post(this.getRoot("setStatusAccessKey"), {license_id, status:"reserve", num})
                if(data.keys.length > 0) window.open(this.getRoot(`downloadCSVAccessKey?keys=${encodeURIComponent(data.keys)}`), "_blank");
                return data.access_keys
        }

        async recallAKeys(license_id : number, num:number) {
                let {data} = await this.post(this.getRoot("setStatusAccessKey"), {license_id, status:"recall", num})
                return data.access_keys
        }
}

export const licenseApi = new CLicenseApi('licenses');
