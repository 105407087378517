<template>
        <Drawer v-model:visible='theModel' position='right' class='slidePanel editPanelLicense' :dismissable='false'>
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class='editingbody'>
                        <Tabs value='0'>

                                <TabList>
                                        <Tab value='0' @click='visibleAKeysButtons = false'>Свойства</Tab>
                                        <Tab value='1' @click='visibleAKeysButtons = false'>Конфигурация (JSON)</Tab>
                                        <Tab value='2' @click='visibleAKeysButtons = true' v-if='!!editingData.id && license.is_keys'>Ключи доступа</Tab>
                                </TabList>

                                <TabPanels>
                                        <TabPanel value='0' class='px-3"'>
                                                <UDropdownBox name='Статус лицензии' :options='statuses_license' v-model='license.status' class='mt-3'></UDropdownBox>

                                                <UInputField type='input' v-model='license.name' name='Наименование лицензии' class='mt-3' :asterics=true :limit=100></UInputField>

                                                <div class='mt-3'>
                                                        <div class='flex align-items-center'>
                                                                <label class='mr-2'>Контракт:</label>
                                                                <div class='w-full mr-2'><b>{{ viewSelectContract }}</b></div>
                                                                <Button @click='selectContract' v-if='!editingData.id'>Выбрать</Button>
                                                        </div>
                                                </div>

                                                <div class='mt-3'>
                                                        <div class='flex align-items-center'>
                                                                <label class='mr-2 flex-shrink-0'>Программный продукт:</label>
                                                                <div class='w-full mr-2'><b>{{ viewSelectProduct }}</b></div>
                                                                <Button @click='selectProduct' v-if='!editingData.id'>Выбрать</Button>
                                                        </div>
                                                </div>

                                                <UCheckBox name='Приоритет в договоре:' v-model='license.priority' class='mt-4' />

                                                <div class='mt-3'>
                                                        <span class='mr-2'>Дата начала:</span>
                                                        <DatePicker v-model='active_from' class='mr-4' dateFormat='dd.mm.yy' v-if='!editingData.id' />
                                                        <span class='mr-4' v-if='editingData.id'><b>{{ common.dateFormatView(active_from) }}</b></span>
                                                        <span class='mr-2'>Дата окончания:</span>
                                                        <DatePicker v-model='active_to' dateFormat='dd.mm.yy' v-if='!editingData.id' />
                                                        <span v-if='editingData.id'><b>{{ common.dateFormatView(active_to) }}</b></span>
                                                </div>

                                                <div class='mt-3' v-if='!editingData.id'>
                                                        <UCheckBox name='Неограниченное количество доступов: ' v-model='license.is_access_unlimited' class='mt-3' @change='changeIsAccessUnlimited' />
                                                        <div class='flex mt-3 align-items-baseline' v-if='!license.is_access_unlimited'>
                                                                <UInputField type='number' v-model='license.access_quantity'
                                                                             name='Количество доступов:' class='line flex-shrink-0' style='width:auto' :limit=10 :styleInput="{width: '150px'}">
                                                                </UInputField>
                                                        </div>
                                                </div>
                                                <div class='mt-3' v-if='editingData.id'>
                                                        <div class='flex mt-3 align-items-baseline' v-if='license.is_access_unlimited'>
                                                                Неограниченное количество доступов. Выдано:&nbsp<b>{{ license.access_granted }}</b>
                                                        </div>
                                                        <div class='flex mt-3 align-items-baseline' v-if='!license.is_access_unlimited'>
                                                                Выдано доступов:&nbsp<b>{{ license.access_granted }}</b>&nbspиз&nbsp<b>{{ license.access_quantity }}</b>
                                                        </div>
                                                </div>

                                                <UCheckBox v-if='!editingData.id' name='Использовать лицензионные ключи' v-model='license.is_keys' class='mt-3' />

                                                <div class='mt-3' v-if='!editingData.id'>
                                                        <span class='mr-2'>Действия доступа до даты:</span>
                                                        <DatePicker v-model='access_expiration_at' dateFormat='dd.mm.yy' showButtonBar />
                                                </div>
                                                <div class='mt-3' v-if='editingData.id'>
                                                        <span class='mr-2' v-if='editingData.access_expiration_at'>Действия доступа до даты:</span>
                                                        <span class='mr-2' v-if='!editingData.access_expiration_at'>Время доступа не ограничено</span>
                                                        <span v-if='editingData.access_expiration_at'><b>{{ common.dateFormatView(access_expiration_at) }}</b></span>
                                                </div>

                                                <div class='mt-3' v-if='!editingData.id'>
                                                        <UDropdownBox name='Период лицензии:' :options='period_license' v-model='license.access_period'></UDropdownBox>
                                                </div>
                                                <div class='mt-3' v-if='editingData.id'>
                                                        <span class='mr-2'>Период лицензии:</span>
                                                        <span class='mr-2'><b>{{ period_license.filter((x) => x.id == license.access_period)[0].name }}</b></span>
                                                </div>

                                                <UInputField v-model='license.comment' name='Комментарий:' class='mt-3' :rows='5'></UInputField>
                                        </TabPanel>

                                        <TabPanel value='1'>
                                                <UInputField v-model='license.scope_json' name='Блок данных' class='mt-3' :rows='25'></UInputField>
                                        </TabPanel>
                                        <TabPanel value='2' v-if='!!editingData.id && license.is_keys'>
                                                <div class='w-full flex flex-column h-full py-3'>
                                                        <ListBase :columns='key_columns' :apiLoader='akeysApi'
                                                                  class='listBase'
                                                                  :enableAdd='false'
                                                                  :hard_filters='hard_filters'
                                                                  :sortField="{field:'id', direction : 'DESC'}"
                                                                  ref='list'>
                                                        </ListBase>
                                                </div>
                                        </TabPanel>
                                </TabPanels>
                        </Tabs>
                </div>

                <div class='downButtonPanel'>
                        <Button @click='save' v-if='!isCreate' :disabled='disableSave'>Сохранить</Button>
                        <Button @click='save' v-if='isCreate' :disabled='disableSave'>Создать</Button>
                        <Button @click='dialogCreateAKeys' v-if='visibleAKeysButtons && license.is_access_unlimited'
                                class='ml-4'>
                                Создать
                        </Button>
                        <Button @click='dialogReserveNumAKeys' v-if='visibleAKeysButtons'
                                :disabled='numKeysWithStatusCreate <= 0'
                                class='ml-4'>
                                Зарезервировать
                        </Button>
                        <Button @click='dialogRecallNumAKeys' v-if='visibleAKeysButtons'
                                :disabled='numKeysWithStatusCreate <= 0'
                                class='ml-4'>
                                Отозвать
                        </Button>
                </div>

                <Dialog v-model:visible='showCreateNumAKeys' modal header='Создание ключей' :style="{ width: '25rem' }">
                        <label class='w-24'>Укажите какое количество ключей вы хотите создать.</label>
                        <div class='flex flex align-items-center gap-4 mb-4 mt-4'>
                                <UInputField name='Создать ключей:' type='number' v-model='numCreateAKeys' inputId='integeronly'/>
                        </div>
                        <div class='flex justify-end'>
                                <Button type='button' label='Продолжить' @click='executeCreateAKeys'></Button>
                        </div>
                </Dialog>

                <Dialog v-model:visible='showReserveNumAKeys' modal header='Резервирование ключей' :style="{ width: '25rem' }">
                        <label class='w-24'>Укажите какое количество ключей вы хотите зарезервировать. Максимальное значение: <b>{{ numKeysWithStatusCreate }}</b></label>
                        <div class='flex flex align-items-center gap-4 mb-4 mt-4'>
                                <UInputField name='Зарезервировать ключей:' type='number' v-model='numReserveAKeys' inputId='integeronly'
                                             :min='1' :max='numKeysWithStatusCreate' />
                        </div>
                        <div class='flex justify-end'>
                                <Button type='button' label='Продолжить' @click='executeReserveAKeys' :disabled='numKeysWithStatusCreate <= 0'></Button>
                        </div>
                </Dialog>

                <Dialog v-model:visible='showRecallNumAKeys' modal header='Отзыв ключей' :style="{ width: '25rem' }">
                        <label class='w-24'>Укажите какое количество ключей вы хотите отозвать. Максимальное значение: {{ numKeysWithStatusCreate }}</label>
                        <div class='flex flex align-items-center gap-4 mb-1 mt-4'>
                                <UInputField name='Отозвать ключей:' type='number' v-model='numRecallAKeys' inputId='integeronly'
                                             :min='1' :max='numKeysWithStatusCreate' />
                        </div>
                        <div class='mb-4'>
                                <label v-show='isRecallShowing'>Ключи отозваны.</label>&nbsp
                        </div>
                        <div class='flex justify-end'>
                                <Button type='button' label='Продолжить' @click='executeRecallAKeys' :disabled='numKeysWithStatusCreate <= 0'></Button>
                        </div>
                </Dialog>

                <Dialog v-model:visible='dialogSelectContract' modal header='Выбор контракта' style='width : 1000px'>
                        <div class='flex flex-column'>
                                <div class='flex w-full mb-3 align-items-end'>
                                        <UInputField type='input' v-model='searchContractText' name='Поиск' @input='inputSearchContract'></UInputField>
                                </div>
                                <ListBase :columns='columnsContract' :apiLoader='contractApi' v-model='selectedContract' class='listBase'
                                          :editEnable='false' :enableAdd='false'
                                          :sortField="{field:'id', direction : 'DESC'}" ref='listContract'
                                          :custom_filters='customFilterContract'
                                ></ListBase>
                        </div>
                        <template #footer>
                                <Button label='Выбрать' @click='doSelectedContract' />
                        </template>
                </Dialog>

                <Dialog v-model:visible='dialogSelectProduct' modal header='Выбор программного продукта' style='width : 1000px'>
                        <div class='flex flex-column'>
                                <div class='flex w-full mb-3 align-items-end'>
                                        <UInputField type='input' v-model='searchProductText' name='Поиск' @input='inputSearchProduct'>
                                        </UInputField>
                                </div>
                                <ListBase :columns='columnsProduct' :apiLoader='productApi' v-model='selectedProduct' class='listBase'
                                          :editEnable='false' :enableAdd='false'
                                          :sortField="{field:'id', direction : 'DESC'}" ref='listProduct'
                                          :custom_filters='customFilterProduct'
                                ></ListBase>
                        </div>
                        <template #footer>
                                <Button label='Выбрать' @click='doSelectedProduct' />
                        </template>
                </Dialog>
        </Drawer>
</template>

<script setup lang='ts'>
import { ref, computed, watch } from 'vue'
import { CAdditionDataLicense, CFilterContract, CFilterProduct, CFilterId, CLicense, CLicenseData } from '@/interfaces'
import controllerEditor from '@/editors/controller/controllerEditor'
import { BillingStore } from '@/ts/store'
import common from '@/ts/common'
import UCheckBox from '@/components/UCheckBox.vue'
import ListBase from '@/components/ListBase.vue'
        import { akeysApi, contractApi, productApi, licenseApi } from '@/ts/api'

        // @ts-ignore
        const props = defineProps<{ modelValue: boolean, editingData: CLicense, additionalEditorData: CAdditionDataLicense }>()
        // @ts-ignore
        const emit = defineEmits(['update:modelValue', 'save'])
        let cStore = BillingStore()

        const products = computed(() => cStore.products)
        const statuses_license = computed(() => cStore.statuses_license)
        const period_license = computed(() => cStore.period_license)

        const license = computed(() => props.editingData)
        const license_data = ref(new CLicenseData(props.additionalEditorData.license_data))

        const recalculateNumberOfReserveAndRecallKeys = (akeys: any) => {
                let obj = akeys.find(x => x.status == 'create')
                if (obj) return obj.count
                return 0
        }

        const visibleAKeysButtons = ref(false)
        const showCreateNumAKeys = ref(false)
        const showReserveNumAKeys = ref(false)
        const showRecallNumAKeys = ref(false)
        const numCreateAKeys = ref(0)
        const numReserveAKeys = ref(0)
        const numRecallAKeys = ref(0)
        const isRecallShowing = ref(false)
        const numKeysWithStatusCreate = ref(recalculateNumberOfReserveAndRecallKeys(props.additionalEditorData.access_keys))
        const dialogCreateAKeys = () => {
                numCreateAKeys.value = 1
                showCreateNumAKeys.value = true
        }
        const dialogReserveNumAKeys = () => {
                numReserveAKeys.value = 1
                showReserveNumAKeys.value = true
        }
        const dialogRecallNumAKeys = () => {
                numRecallAKeys.value = 1
                showRecallNumAKeys.value = true
        }

        const executeCreateAKeys = async () => {
                if (!license.value.id) return
                showCreateNumAKeys.value = false
                let akeys = await licenseApi.createAKeys(license.value.id, numCreateAKeys.value)
                list.value.onLoadList()
                numKeysWithStatusCreate.value = recalculateNumberOfReserveAndRecallKeys(akeys)
        }

        const executeReserveAKeys = async () => {
                if (!license.value.id) return
                showReserveNumAKeys.value = false
                let akeys = await licenseApi.reserveAKeys(license.value.id, numReserveAKeys.value)
                list.value.onLoadList()
                numKeysWithStatusCreate.value = recalculateNumberOfReserveAndRecallKeys(akeys)
        }

        const executeRecallAKeys = async () => {
                isRecallShowing.value = false
                if (!license.value.id) return
                //showRecallNumAKeys.value = false
                let akeys = await licenseApi.recallAKeys(license.value.id, numRecallAKeys.value)
                list.value.onLoadList()
                numKeysWithStatusCreate.value = recalculateNumberOfReserveAndRecallKeys(akeys)
                if (numKeysWithStatusCreate.value <= 0) {
                        showRecallNumAKeys.value = false
                }
                numRecallAKeys.value = 1
                isRecallShowing.value = true
        }

        const active_from = ref(common.dateDate(license.value.active_from))
        const active_to = ref(common.dateDate(license.value.active_to))
        const access_expiration_at = ref(common.dateDate(license.value.access_expiration_at))

        watch(active_from, () => license.value.active_from = (common.dateFormatView(active_from.value)) as string)
        watch(active_to, () => license.value.active_to = (common.dateFormatView(active_to.value)) as string)
        watch(access_expiration_at, () => license.value.access_expiration_at = common.dateFormatView(access_expiration_at.value))

        const key_columns = [
                { id: 'value', header: 'Ключ доступа', style: 'width : 50%' },
                { id: 'access_keys_status_name', header: 'Статус', style: 'width: 50%', sortable: true }
        ]

        const hard_filters = ref({ id: license.value.id } as CFilterId)
        const list = ref()

        const changeIsAccessUnlimited = () => {
                license.value.access_quantity = 0
        }

        watch(() => license.value.is_access_unlimited, () => {
                console.log('watch', license.value.is_access_unlimited)
        })

        // Выбор контракта
        const viewSelectContract = computed(() => {
                if (!license.value.contract_id) return 'Не выбран'
                else return `${license_data.value.name} (${license_data.value.actor} - ${license_data.value.client})`
        })
        const dialogSelectContract = ref(false)
        const columnsContract = [
                { id: 'id', header: 'ID', style: 'width : 75px flex-shrink:0', sortable: true },
                { id: 'name', header: 'Название', style: 'width : 40%', sortable: true },
                { id: 'actor', header: 'Актор', style: 'width : 30%', sortable: true },
                { id: 'client', header: 'Контрагент', style: 'width : 30%', sortable: true }
        ]
        const selectedContract = ref()
        const searchContractText = ref()
        const customFilterContract = ref(null)
        const listContract = ref()
        const selectContract = () => {
                customFilterContract.value = null
                searchContractText.value = ''
                dialogSelectContract.value = true
        }
        let timerSearchContractID: any = 0
        const inputSearchContract = () => {
                if (timerSearchContractID > 0) {
                        clearTimeout(timerSearchContractID)
                        timerSearchContractID = 0
                }
                timerSearchContractID = setTimeout(execTimerSearchContract, 500)
        }
        const execTimerSearchContract = () => {
                clearTimeout(timerSearchContractID)
                timerSearchContractID = 0
                if (searchContractText.value && (searchContractText.value.trim().length > 1)) {
                        customFilterContract.value = new CFilterContract()
                        customFilterContract.value.search = searchContractText.value
                } else {
                        customFilterContract.value = null
                }
                listContract.value.onLoadList()
        }
        const doSelectedContract = () => {
                dialogSelectContract.value = false
                license.value.contract_id = selectedContract.value.id
                license_data.value.loadContract(selectedContract.value)
        }

        // Выбор программного продукта
        const viewSelectProduct = computed(() => {
                if (!license.value.product_id) return 'Не выбран'
                else return `${license_data.value.product}`
        })
        const dialogSelectProduct = ref(false)
        const columnsProduct = [
                { id: 'id', header: 'ID', style: 'width : 75px flex-shrink:0', sortable: true },
                { id: 'name', header: 'Название', style: 'width : 100%', sortable: true }
        ]
        const selectedProduct = ref()
        const searchProductText = ref()
        const customFilterProduct = ref(null)
        const listProduct = ref()
        const selectProduct = () => {
                customFilterProduct.value = null
                searchProductText.value = ''
                dialogSelectProduct.value = true
        }
        let timerSearchProductID: any = 0
        const inputSearchProduct = () => {
                if (timerSearchProductID > 0) {
                        clearTimeout(timerSearchProductID)
                        timerSearchProductID = 0
                }
                timerSearchProductID = setTimeout(execTimerSearchProduct, 500)
        }
        const execTimerSearchProduct = () => {
                clearTimeout(timerSearchProductID)
                timerSearchProductID = 0
                if (searchProductText.value && (searchProductText.value.trim().length > 1)) {
                        customFilterProduct.value = new CFilterProduct()
                        customFilterProduct.value.name = searchProductText.value
                } else {
                        customFilterProduct.value = null
                }
                listProduct.value.onLoadList()
        }
        const doSelectedProduct = () => {
                dialogSelectProduct.value = false
                license.value.product_id = selectedProduct.value.id
                license_data.value.loadProduct({ product: selectedProduct.value.name })
        }

        const {
                theModel, editingData,
                isCreate, disableSave, header,
                save
        } = controllerEditor({
                props, emit,
                headerCreate: 'Создание лицензии',
                headerEdit: 'Редактирование лицензии ' + license.value.id
        })

        if (isCreate.value) {
                license.value.status = statuses_license.value[0].id
                license.value.access_period = period_license.value[0].id
        }

</script>
