<template>
        <div class='frameWindow accesskeysWindow' v-if='loaded'>
                <h3>Ключи</h3>
                <Tabs class='objectsTab' value='0' @update:value='updateTab'>
                        <TabList>
                                <Tab value='0'>Все</Tab>
                                <Tab value='1' @click='hideFilter = false'>Фильтр</Tab>
                        </TabList>
                </Tabs>

                <FilterAccessKeysPanel v-show='isViewFilter' @hide='() => hideFilter = true' @apply='applyFilter' @clear='clearFilter'></FilterAccessKeysPanel>

                <div class='bodyFrame'>
                        <div class='w-full flex flex-column h-full'>
                                <ListBase :columns='columns' :apiLoader='accesskeyApi' v-model='selectedObject'
                                          class='listBase'
                                          :sortField="{field:'id', direction : 'DESC'}"
                                          :editingRow='false'
                                          :elipsisSupport='true'
                                          :rowsPage='50'
                                          empty = "Ключей не найдено"
                                          :custom_filters='customFilter'
                                          ref='list'
                                          @addRow='addRow'
                                          @loaded='onLoadedList'>
                                        <template #status='{data}'>
                                                <div class="akey_status_circle" :class="data.data.status" :title='data.data.access_keys_status_name'></div>
                                        </template>
                                </ListBase>
                        </div>
                </div>
        </div>
</template>

<script setup lang='ts'>
        import controller from '@/window/controller/controller'
        import { accesskeyApi } from '@/ts/api'
        import ListBase from '@/components/ListBase.vue'
        import FilterAccessKeysPanel from '@/panels/FilterAccessKeysPanel.vue'
        import { CFilterAccessKey } from '@/interfaces/CFilterAccessKey';
        import { CAccessKey } from '@/interfaces/CAccessKey';

        const columns = [
                { id: 'license_name', header: 'Лицензия', style: 'width : 50%', sortable: true },
                { id: 'product_name', header: 'Продукт', style: 'width : 50%', sortable: true },
                { id: 'value', header: 'Ключ', style: 'width : 150px', sortable: true },
                { id: 'status', header: 'Статус', style: 'width : 116px; display: flex; justify-content: center', sortable: true }
        ]

        const {
                loaded, list, selectedObject, customFilter,
                onLoadedList,
                activeTab, updateTab,
                hideFilter, isViewFilter, applyFilter, clearFilter,
                visibleEditor, editingData, addRow, editRow, save, removeRow
        } = controller({
                filterTabIndex: '1',
                supportTab: true,
                supportPrepareEdit: false,
                classFilter: CFilterAccessKey,
                classRow: CAccessKey,
                api: accesskeyApi,
                reloadSaveFields: ['name'],
                removeMessage: 'Вы действительно хотите удалить Программный продукт?'
        })

</script>
