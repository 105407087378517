<template>
        <div class='filterPanel mt-2'>
                <div class='flex'>
                        <UInputField name='Поиск по названию' type='input' class='lineInput w-7' v-model='curFilter.name'></UInputField>
                        <UInputField name='По ID' type='input' class='lineInput w-1 ml-4' v-model='curFilter.id'></UInputField>
                        <UDropdownBox name='Программный продукт' :options='products' v-model='curFilter.product_id' class='ml-4 w-4' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
                        <div class='buttonPanel flex-shrink-0 ml-4 mt-3' style='width: 340px; height: 36px;'>
                                <Button class='p-button-text' @click='clearFilter'>Сбросить</Button>
                                <Button class='ml-4' @click='applyFilter'>Применить</Button>
                                <Button class='ml-4 p-button-outlined' @click='hide'>Скрыть</Button>
                        </div>
                </div>
        </div>
</template>

<script setup lang='ts'>
import { computed, ref } from 'vue';
import { CFilterProgclient } from '@/interfaces';
import controllerFilter from '@/panels/controller/controllerFilter';
import { BillingStore } from '@/ts/store';

const emit = defineEmits(['hide', 'apply', 'clear']);

let cStore = BillingStore();

const products = computed(() => cStore.products);

const {
        curFilter,
        hide, applyFilter, clearFilter
} = controllerFilter({
        emit,
        classFilter: CFilterProgclient
});

</script>
