import {CFilterId} from "@/interfaces";

export class CFilterAccessKey extends CFilterId {
        searchText : string
        inLicense : boolean
        inProduct : boolean
        inKey : boolean

        constructor(flt : CFilterAccessKey|null = null) {
                super(flt)

                if(flt === null) {
                        this.searchText = ""
                        this.inLicense = this.inProduct = this.inKey = true
                } else {
                        this.searchText = flt.searchText
                        this.inLicense = flt.inLicense
                        this.inProduct = flt.inProduct
                        this.inKey = flt.inKey
                }
        }

}
