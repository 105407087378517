<template>
        <div class='filterPanel mt-2'>
                <div class='flex'>
                        <UInputField name='Поиск по названию' type='input' class='lineInput w-4' v-model='curFilter.name'></UInputField>
                        <UInputField name='По ID' type='input' class='lineInput w-2 ml-4' v-model='curFilter.id'></UInputField>
                        <UDropdownBox name='Актор' :options='actors' v-model='curFilter.actor_id' class='ml-4 w-2' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
                        <UDropdownBox name='Контрагент' :options='clients' v-model='curFilter.client_id' class='ml-4 w-2' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
                        <UDropdownBox name='Статус' :options='statuses' v-model='curFilter.status' optionLabel='name' class='ml-4 w-2' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
                </div>
                <div class='flex mt-3'>
                        <div class='w-2'>
                                <label>Дата начала</label>
                                <DatePicker show-button-bar v-model='active_from' class='w-full' dateFormat='dd.mm.yy' @clear-click="active_from = ''"/>
                        </div>
                        <div class='ml-4 w-2'>
                                <label>Дата окончания</label>
                                <DatePicker show-button-bar v-model='active_to' class='w-full' dateFormat='dd.mm.yy'  @clear-click="active_to = ''"/>
                        </div>
                        <div class='buttonPanel flex-shrink-0 ml-4 mt-3 w-8 align-items-end' style='width: 340px'>
                                <Button class='p-button-text' @click='clearFilterLocal'>Сбросить</Button>
                                <Button class='ml-4' @click='applyFilter'>Применить</Button>
                                <Button class='ml-4 p-button-outlined' @click='hide'>Скрыть</Button>
                        </div>
                </div>
        </div>
</template>

<script setup lang='ts'>
        import { computed, ref, watch } from 'vue'
        import { CFilterContract } from '@/interfaces'
        import controllerFilter from '@/panels/controller/controllerFilter'
        import common from '@/ts/common'
        import { BillingStore } from '@/ts/store'

        const emit = defineEmits(['hide', 'apply', 'clear'])
        let cStore = BillingStore()

        const actors = computed(() => cStore.actors)
        const clients = computed(() => cStore.clients)
        const statuses = computed(() => cStore.statuses)

        const {
                curFilter,
                hide, applyFilter, clearFilter
        } = controllerFilter({
                emit,
                classFilter: CFilterContract
        })

        const active_from = ref<Date|null>(null)
        const active_to = ref<Date|null>(null)

        const clearFilterLocal = () => {
                active_from.value = null
                active_to.value = null
                clearFilter()
        }

        watch(active_from, () => curFilter.value.active_from = common.dateFormatSave(active_from.value))
        watch(active_to, () => curFilter.value.active_to = common.dateFormatSave(active_to.value))
</script>
