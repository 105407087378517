import { createWebHistory, createRouter } from 'vue-router';
import ActorsWindow from '../window/ActorsWindow.vue';
import ClientsWindow from '../window/ClientsWindow.vue';
import ContractsWindow from '../window/ContractsWindow.vue';
import ProductsWindow from '../window/ProductsWindow.vue';
import ProgclientsWindow from '../window/ProgclientsWindow.vue';
import LicensesWindow from '../window/LicensesWindow.vue';
import AccessKeysWindow from '../window/AccessKeysWindow.vue';
import Login from '../window/Login.vue';

//@ts-ignore
export const router = createRouter({
        history: createWebHistory(''),
        routes: [
                { path: '/actors', name: 'actors', component: ActorsWindow },
                { path: '/clients', name: 'clients', component: ClientsWindow },
                { path: '/contracts', name: 'contracts', component: ContractsWindow },
                { path: '/products', name: 'products', component: ProductsWindow },
                { path: '/progclients', name: 'progclients', component: ProgclientsWindow },
                { path: '/licenses', name: 'licenses', component: LicensesWindow },
                { path: '/accesskeys', name: 'accesskeys', component: AccessKeysWindow },
                {path: '/login', name: 'login', component: Login, meta: {forVisitors: true}}
        ]
});
