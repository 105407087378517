export interface IColumnData {
        id : string
        header : string
        sortable : boolean
        style : string,
        templated : boolean
}

export const getTColumnData = () => {
        return [{id: "id", header: "header", sortable: true, style: "", templated: false}]
}

export type TClearData = {
        page : number
        field : string,
        direction : string
}

export type TSortData = {
        field : string,
        direction : string
}

export interface IListElement {
        id: number;
}

export interface IListNameElement {
        id: number|string;
        name: string;
}
