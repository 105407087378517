<template>
        <div class='filterPanel mt-2'>
                <div class='flex'>
                        <div class='w-12'>
                                <UInputField name='Поиск' type='input' class='lineInput ' v-model='curFilter.searchText'></UInputField>
                                <div class='flex mt-2'>
                                        <UCheckBox v-model='curFilter.inKey' name="Ключ" class='mr-4'></UCheckBox>
                                        <UCheckBox v-model='curFilter.inLicense' name='Лицензия' class='mr-4'></UCheckBox>
                                        <UCheckBox v-model='curFilter.inProduct' name="Продукт" class='mr-4'></UCheckBox>
                                </div>
                        </div>
                        <div class='buttonPanel flex-shrink-0 ml-4 mt-3 align-items-start' style='width: 340px'>
                                <Button class='p-button-text' @click='clearFilter'>Сбросить</Button>
                                <Button class='ml-4' @click='applyFilter'>Применить</Button>
                                <Button class='ml-4 p-button-outlined' @click='hide'>Скрыть</Button>
                        </div>
                </div>
        </div>
</template>

<script setup lang='ts'>
        import controllerFilter from '@/panels/controller/controllerFilter'
        import { CFilterAccessKey } from '@/interfaces/CFilterAccessKey';

        // @ts-ignore
        const emit = defineEmits(['hide', 'apply', 'clear'])

        const {
                curFilter,
                hide, applyFilter, clearFilter
        } = controllerFilter({
                emit,
                classFilter: CFilterAccessKey
        })

</script>
