import { createApp } from 'vue'
import App from './App.vue'
import { router } from './ts/router'
import PrimeVue from 'primevue/config'
import { MyPreset } from './ts/presetPV'
import { createPinia } from 'pinia'

import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import Drawer from 'primevue/drawer'
import Dialog from 'primevue/dialog'
import Checkbox from 'primevue/checkbox'
import DatePicker from 'primevue/datepicker'
import Select from 'primevue/select'
import Listbox from 'primevue/listbox'
import InputNumber from 'primevue/inputnumber'
import UInputField from '@/components/UInputField.vue'
import UListBox from '@/components/UListBox.vue'
import UDropdownBox from '@/components/UDropdownBox.vue'
import UCheckBox from '@/components/UCheckBox.vue'

import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

//import InputIcon from 'primevue/inputicon'
//import IconField from 'primevue/iconfield'

createApp(App)
        .use(createPinia())
        .use(router)
        .use(PrimeVue, {
                theme: {
                        preset: MyPreset
                }
        })
        .component('Tabs', Tabs)
        .component('TabList', TabList)
        .component('Tab', Tab)
        .component('TabPanels', TabPanels)
        .component('TabPanel', TabPanel)
        .component('Button', Button)
        .component('DataTable', DataTable)
        .component('Column', Column)
        .component('ColumnGroup', ColumnGroup)
        .component('Row', Row)
        .component('Drawer', Drawer)
        .component('Dialog', Dialog)
        .component('Checkbox', Checkbox)
        .component('DatePicker', DatePicker)
        .component('Select', Select)
        .component('UInputField', UInputField)
        .component('UListBox', UListBox)
        .component('UDropdownBox', UDropdownBox)
        .component('UCheckBox', UCheckBox)
        .component('Listbox', Listbox)
        .component('InputNumber', InputNumber)
        .mount('#app')

import './less/index.less'
