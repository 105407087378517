import { CEditing, CEditTemplate, IListNameElement } from '@/interfaces';
import common from '@/ts/common';

export class CAccessKey extends CEditing {
        value : string
        license_id: number
        status : number
        access_id: number

        constructor(data: CAccessKey | null = null) {
                super(data);

                if (data == null) {
                        this.value = '';
                        this.license_id = 0;
                        this.status = 0;
                        this.access_id = 0;
                } else {
                        this.value = data.value;
                        this.license_id = data.license_id;
                        this.status = data.status;
                        this.access_id = data.access_id;
                }
        }

        fillForSave(dataTo: CAccessKey) {
                dataTo.value = this.value.trim();
                dataTo.license_id = this.license_id;
                dataTo.status = this.status;
                dataTo.access_id = this.access_id;
        }

        isDisableSave(): boolean {
                return this.value.trim() === ''
        }
}


export  class CAdditionDataAccessKey extends CEditTemplate {
        statuses : IListNameElement[]

        constructor(data: CAdditionDataAccessKey) {
                super(data);
                this.statuses = data.statuses
        }
}
